import { useContext } from "react";
import useSWR, { mutate } from "swr";
import { apiRequest } from "./base";
import { ApiCoreContext } from "./context";


function useProfile(username, options) {
    const { data, error } = useSWR(
        username ? `/v1.0/customers/profiles/${username}/` : null,
        options || {}
    );
    return {
        profile: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useMe(options) {
    const { data, error } = useSWR(`/v1.0/customers/me/`, options || {});
    return {
        profile: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function mutateMe(...options) {
    return mutate(`/v1.0/customers/me/`, ...options);
}

async function createProfile(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/customers/profiles/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

async function updateProfile(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/customers/me/`,
        { method: "POST", body: params }
    );
    return {
        data,
        status,
    };
}

async function resendVerificationEmail(context) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/customers/me/request-verification-email/`,
        { method: "POST" }
    );
    return {
        data,
        status,
    };
}

export function customersService() {
    const context = useContext(ApiCoreContext);
    return {
        useProfile: (...params) => useProfile(...params),
        useMe: (...params) => useMe(...params),
        mutateMe: (...params) => mutateMe(...params),
        createProfile: (...params) => createProfile(context, ...params),
        updateProfile: (...params) => updateProfile(context, ...params),
        resendVerificationEmail: (...params) =>
            resendVerificationEmail(context, ...params),
    };
}
