import { useContext } from "react";
import { apiRequest } from "./base";
import { ApiCoreContext } from "./context";


async function loginRequest(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/authentication/login/request/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

async function login(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/authentication/login/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

export function authService() {
    const context = useContext(ApiCoreContext);
    return {
        loginRequest: (...params) => loginRequest(context, ...params),
        login: (...params) => login(context, ...params),
    };
}
