import useSWR from "swr";


function useText(text, options) {
    const { data, error } = useSWR(`/v1.0/extract/text/?text=${text}`, options || {});
    return {
        tokens: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useTextDigest(text, options) {
    const { data, error } = useSWR(`/v1.0/extract/text-digest/?text=${text}`, options || {});
    return {
        diggest: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function extractService() {
    return {
        useText: (...params) => useText(...params),
        useTextDigest: (...params) => useTextDigest(...params),
    };
}
