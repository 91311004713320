import { useRouter } from 'next/router';
import React, { useState } from "react";

export const GlobalContext = React.createContext({
    navigationHistory: [],
    pushNavigationHistory: () => { },
});

export const GlobalContextStorage = ({ children }) => {
    const [navigationHistory, setNavigationHistory] = useState([]);
    const router = useRouter();

    const pushNavigationHistory = () => {
        setNavigationHistory([router.asPath, ...navigationHistory]);
    };

    return (
        <GlobalContext.Provider
            value={{
                navigationHistory,
                pushNavigationHistory
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
