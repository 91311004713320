import { useContext } from "react";
import useSWR, { mutate } from "swr";
import { apiRequest } from "./base";
import { ApiCoreContext } from "./context";


function useRecipeById(id, options) {
    const { data, error } = useSWR(
        id ? `/v1.0/food/recipes/${id}/` : null,
        options || {}
    );
    return {
        recipe: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useRecipeBySlug(username, slug, options) {
    const { data, error } = useSWR(
        slug ? `/v1.0/food/recipes/${username}/${slug}/` : null,
        options || {}
    );
    return {
        recipe: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useRecipes(options) {
    const { data, error } = useSWR(`/v1.0/food/recipes/`, options || {});
    return {
        recipes: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function mutateRecipe(id, ...options) {
    return mutate(`/v1.0/food/recipes/${id}/`, ...options);
}

async function createRecipe(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/food/recipes/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

async function updateRecipe(context, id, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/food/recipes/${id}/`,
        { method: "PATCH", json: params }
    );
    return {
        data,
        status,
    };
}

export function foodService() {
    const context = useContext(ApiCoreContext);
    return {
        useRecipeById: (...params) => useRecipeById(...params),
        useRecipeBySlug: (...params) => useRecipeBySlug(...params),
        useRecipes: (...params) => useRecipes(...params),
        mutateRecipe: (...params) => mutateRecipe(...params),
        createRecipe: (...params) => createRecipe(context, ...params),
        updateRecipe: (...params) => updateRecipe(context, ...params),
    };
}
