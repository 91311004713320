export const fetcherBuilder = (root) =>
    async (...args) => {
        const authToken = localStorage.getItem("apiToken");
        const headers = {
            Accept: "application/json",
            ...(authToken && { Authorization: `Token ${authToken}` }),
        };
        const res = await fetch(`${root}${args[0]}`, { headers });
        if (!res.ok) {
            const error = new Error(
                "An error occurred while fetching the data."
            );
            error.info = await res.json();
            error.status = res.status;
            error.loginRequired = res.status === 401;
            throw error;
        }
        return res.json();
    };

export const ssrFetcherBuilder =
    (root) =>
        async (...args) => {
            const res = await fetch(`${root}${args[0]}`);
            if (!res.ok) throw new Error(res.statusText);
            return res.json();
        };

export const SSRfetcher = ssrFetcherBuilder(
    process.env.NEXT_PUBLIC_API_PRIVATE_URL
);

export { ApiCoreContext } from "./context";
export * from "./auth";
export * from "./food";
export * from "./customers";
export * from "./extract";
export * from "./library";
