import { SWRConfig } from "swr";
import { ApiCoreContext, fetcherBuilder } from "../api/index";
import { GlobalContextStorage } from "../components/context";
import "../styles/tailwind.css";


const envFetcher = fetcherBuilder(process.env.NEXT_PUBLIC_API_BASE_URL);

function MyApp({ Component, pageProps }) {
    return (
        <GlobalContextStorage>
            <ApiCoreContext.Provider
                value={{ environment: process.env.NEXT_PUBLIC_API_BASE_URL }}
            >
                <SWRConfig
                    value={{
                        fetcher: envFetcher,
                        revalidateOnMount: true,
                        shouldRetryOnError: false,
                        revalidateOnFocus: false,
                    }}
                >
                    <Component {...pageProps} />
                </SWRConfig>
            </ApiCoreContext.Provider>
        </GlobalContextStorage>
    );
}

export default MyApp;
