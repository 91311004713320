export async function apiRequest(path, options) {
    const authToken = localStorage.getItem("apiToken");
    const headers = options.headers || {};
    const json = options.json || null;
    const method = options.method || "GET";
    const body = options.body || null;
    let response = null;
    let data = "";

    const fetchOptions = {
        method,
        headers: {
            Accept: "application/json",
            ...(authToken && { Authorization: `Token ${authToken}` }),
            ...(json && { "Content-Type": "application/json" }),
            ...headers,
        },
        ...(json != null && {
            body: JSON.stringify(json),
        }),
        ...(body != null && {
            body,
        }),
    };

    try {
        response = await fetch(path, fetchOptions);
    } catch (e) {
        return {
            data: null,
            status: 500,
        };
    }

    if (response.status !== 204 && response.status < 499) {
        data = await response.json();
    } else {
        data = "";
    }

    if (response.status === 401 && data.detail === "Invalid token.") {
        localStorage.removeItem("apiToken");
    }

    return {
        data,
        status: response.status,
    };
}
