import { useContext } from "react";
import useSWR, { mutate } from "swr";
import { apiRequest } from "./base";
import { ApiCoreContext } from "./context";


function useCollection(id, options) {
    const { data, error } = useSWR(
        id ? `/v1.0/library/collections/${id}/` : null,
        options || {}
    );
    return {
        collection: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useCollections(options) {
    const { data, error } = useSWR(`/v1.0/library/collections/`, options || {});
    return {
        collections: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function mutateCollection(id, ...options) {
    return mutate(`/v1.0/library/collections/${id}/`, ...options);
}

function mutateCollections(...options) {
    return mutate(`/v1.0/library/collections/`, ...options);
}

async function createCollection(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/collections/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

async function updateCollection(context, id, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/collections/${id}/`,
        { method: "PATCH", json: params }
    );
    return {
        data,
        status,
    };
}

function useItem(id, options) {
    const { data, error } = useSWR(
        id ? `/v1.0/library/items/${id}/` : null,
        options || {}
    );
    return {
        item: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useItems(options) {
    const { data, error } = useSWR(`/v1.0/library/items/`, options || {});
    return {
        items: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useRecipesItems(query, options) {
    const params = new URLSearchParams(query);
    const { data, error } = useSWR(`/v1.0/library/items/following/?${params.toString()}`, options || {});
    return {
        items: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function useProfileItems(username, options) {
    const { data, error } = useSWR(
        `/v1.0/library/profile-items/${username}`,
        options || {}
    );
    return {
        items: data,
        isLoading: !error && !data,
        isError: error,
    };
}

function mutateItem(id, ...options) {
    return mutate(`/v1.0/library/items/${id}/`, ...options);
}

function mutateItems(...options) {
    return mutate(`/v1.0/library/items/`, ...options);
}

async function createItem(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/items/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

async function updateItem(context, id, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/items/${id}/`,
        { method: "PATCH", json: params }
    );
    return {
        data,
        status,
    };
}

async function deleteItem(context, id) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/items/${id}/`,
        { method: "DELETE" }
    );
    return {
        data,
        status,
    };
}

async function deleteCollection(context, id) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/collections/${id}/`,
        { method: "DELETE" }
    );
    return {
        data,
        status,
    };
}

async function bulkDeleteItems(context, params) {
    const { data, status } = await apiRequest(
        `${context.environment}/v1.0/library/items/bulk-delete/`,
        { method: "POST", json: params }
    );
    return {
        data,
        status,
    };
}

export function libraryService() {
    const context = useContext(ApiCoreContext);
    return {
        useCollection: (...params) => useCollection(...params),
        useCollections: (...params) => useCollections(...params),
        mutateCollection: (...params) => mutateCollection(...params),
        mutateCollections: (...params) => mutateCollections(...params),
        createCollection: (...params) => createCollection(context, ...params),
        updateCollection: (...params) => updateCollection(context, ...params),
        useItem: (...params) => useItem(...params),
        useItems: (...params) => useItems(...params),
        useRecipesItems: (...params) => useRecipesItems(...params),
        mutateItems: (...params) => mutateItems(...params),
        useProfileItems: (...params) => useProfileItems(...params),
        mutateItem: (...params) => mutateItem(...params),
        createItem: (...params) => createItem(context, ...params),
        updateItem: (...params) => updateItem(context, ...params),
        deleteItem: (...params) => deleteItem(context, ...params),
        deleteCollection: (...params) => deleteCollection(context, ...params),
        bulkDeleteItems: (...params) => bulkDeleteItems(context, ...params),
    };
}
